export default {
    checkToken(com) {
        return com.axios.post('/api/admin/pub/checkToken', {
            token: com.$store.state.user.token,
        })
    },
    modifyPassword(com, password, newPassword) {
        return com.axios.post('/api/admin/user/modifyPassword', {
            token: com.$store.state.user.token,
            password: password,
            newPassword: newPassword,
        })
    },
    newToken(com) {
        return com.axios.post('/api/admin/Pub/checkMenu', {
            token: com.$store.state.user.token,
        })
    },
}

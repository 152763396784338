export default {
    path: '/cardorder',
    name: 'cardorder',
    component: () => import(/* webpackChunkName: "cardorder" */ '@/views/base'),
    children: [
        {
            name:'cadOrder',
            path: 'list',
            component: () => import(/* webpackChunkName: "cardorder" */ '@/views/cardorder/list')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "cardorder" */ '@/views/cardorder/modify')
        }
    ]
}

export default {
    path: '/advert',
    name: 'advert',
    component: () => import(/* webpackChunkName: "advert" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "advert" */ '@/views/advert/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "advert" */ '@/views/advert/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "advert" */ '@/views/advert/modify')
        }
    ]
}
export default {
    path: '/express',
    name: 'express',
    component: () => import(/* webpackChunkName: "express" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "express" */ '@/views/express/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "express" */ '@/views/express/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "express" */ '@/views/express/modify')
        }
    ]
}
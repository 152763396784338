export default {
    path: '/voucher',
    name: 'voucher',
    component: () => import(/* webpackChunkName: "voucher" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "voucher" */ '@/views/voucher/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "voucher" */ '@/views/voucher/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "voucher" */ '@/views/voucher/modify')
        },
        {
            path: 'userList',
            component: () => import(/* webpackChunkName: "voucher" */ '@/views/voucher/userList')
        },
    ]
}

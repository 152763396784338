export default {
    path: '/role',
    name: 'role',
    component: () => import(/* webpackChunkName: "role" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "role" */ '@/views/role/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "role" */ '@/views/role/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "role" */ '@/views/role/modify')
        },
        {
            path: 'modifyAccess',
            component: () => import(/* webpackChunkName: "role" */ '@/views/role/modifyAccess')
        }
    ]
}
export default {
    path: '/model',
    name: 'model',
    component: () => import(/* webpackChunkName: "model" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "model" */ '@/views/model/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "model" */ '@/views/model/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "model" */ '@/views/model/modify')
        },
        {
            path: 'menage',
            component: () => import(/* webpackChunkName: "model" */ '@/views/model/menage')
        },
        {
            path: 'addRole',
            component: () => import(/* webpackChunkName: "model" */ '@/views/model/addRole')
        },
        {
            path: 'ruleModify',
            component: () => import(/* webpackChunkName: "model" */ '@/views/model/ruleModify')
        }
    ]
}
export default {
    path: '/commissioncode',
    name: 'commissioncode',
    component: () => import(/* webpackChunkName: "classes" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "classes" */ '@/views/commissioncode/list')
        },
    ]
}
<template>
  <el-menu :router="true" :default-active="defaultActive"  :unique-opened="true">
    <template v-for="(item, key) in menu">
      <template v-if="item.subMenu === undefined || item.subMenu.length === 0">
        <Check :name="item.node" :key="key">
          <el-menu-item :index="item.index" :key="key" :route="item.router">
            <template slot="title">
              <i v-if="item.icon !== undefined" :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>
          </el-menu-item>
        </Check>
      </template>
      <template v-else>
        <Check :name="item.node" :key="key">
          <el-submenu :index="item.index" :key="key">
            <template slot="title">
              <i v-if="item.icon !== undefined" :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>
            <template v-for="(sub, subKey) in item.subMenu">
              <Check :name="sub.node" :key="subKey">
                <el-menu-item :index="sub.index" :key="subKey" :route="sub.router">
                  <template slot="title">
                    <i v-if="sub.icon !== undefined" :class="sub.icon"></i>
                    <span>{{ sub.name }}</span>
                  </template>
                </el-menu-item>
              </Check>
            </template>
          </el-submenu>
        </Check>
      </template>
    </template>
  </el-menu>
</template>

<script>
import menu from '@/menu.js'
import api from "@/api/admin";
import Check from "@/components/Check";

export default {
  name: "MenuList",
  mounted() {
    this.setMenu(this.$route)
  },
  watch: {
    $route(newVal) {
      //登录验证
      api.checkToken(this)
          .then(res => {
            if (res.data.result !== 0) {
              this.$message.error(res.data.message)
              this.$store.commit('nav/clearBreadCrumb')
              this.$store.commit('user/clearToken')
              this.$router.push('/login')
            }
          })
      this.setMenu(newVal)
    }
  },
  methods: {
    setMenu(route) {
      this.$store.commit('nav/clearBreadCrumb')
      this.getBreadCrumb(this.$data.menu, route.path, breadCrumb => {
        breadCrumb.forEach(menu => {
          this.$store.commit('nav/addBreadCrumb', {
            name: menu.name,
            router: menu.router,
          })
        })
        if (breadCrumb.length > 2) {
          this.$data.defaultActive = breadCrumb.reverse()[1].index
        } else {
          this.$data.defaultActive = breadCrumb.reverse()[0].index
        }
      })
    },
    getBreadCrumb(menu, path, callback) {
      menu.forEach(menuItem => {
        if (menuItem.router === path) {
          callback([menuItem])
        } else {
          if (menuItem.subMenu && menuItem.subMenu.length > 0) {
            this.getBreadCrumb(menuItem.subMenu, path, breadCrumb => {
              callback([menuItem].concat(breadCrumb))
            })
          }
        }
      })
    }
  },
  data() {
    return {
      defaultActive: '1',
      menu: menu.data,
    }
  },
  components: {
    Check
  }
}
</script>

<style lang="less" scoped>

</style>

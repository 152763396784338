export default {
    path: '/rewardactive',
    name: 'rewardactive',
    component: () => import(/* webpackChunkName: "finance" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "finance" */ '@/views/rewardactive/list')
        }
    ]
}

export default {
    path: '/infor',
    name: 'infor',
    component: () => import(/* webpackChunkName: "goods" */ '@/views/base'),
    children: [
        {
            name: 'infor',
            path: 'list',
            component: () => import(/* webpackChunkName: "goods" */ '@/views/infor/list')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "goods" */ '@/views/infor/modify')
        },
        {
            path: 'cmodify',
            component: () => import(/* webpackChunkName: "goods" */ '@/views/infor/cmodify')
        },
    ]
}

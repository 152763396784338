export default {
    path: '/coupon',
    name: 'coupon',
    component: () => import(/* webpackChunkName: "coupon" */ '@/views/base'),
    children: [
        {
            name: 'couponList',
            path: 'list',
            component: () => import(/* webpackChunkName: "coupon" */ '@/views/coupon/list')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "coupon" */ '@/views/coupon/modify')
        },
    ]
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import base from "@/layout/base";
import user from './modules/user'
import role from './modules/role'
import node from './modules/node'
import model from './modules/model'
import classes from './modules/classes'
import road from './modules/road'
import terminal from './modules/terminal'
import others from './modules/others'
import simple from './modules/simple'
import address from './modules/address'
import statistics from "./modules/statistics";
import advert from "./modules/advert";
import advertcen from "./modules/advertcen";
import goods from "./modules/goods";
import suning from "./modules/suning";
import pool from "./modules/pool";
import lian from "./modules/lian";
import voucher from "./modules/voucher";
import cardspool from "./modules/cardspool";
import express from './modules/express';
import blind from './modules/blind';
import order from './modules/order';
import cardorder from './modules/cardorder';
import coupon from './modules/coupon';
import draw from './modules/draw';
import customer from './modules/customer';
import finance from './modules/finance';
import salescard from './modules/salescard';
import salescardlist from './modules/salescardlist';
import shopverify from './modules/shopverify';
import commission from './modules/commission';
import shopuser from './modules/shopuser';
import articles from './modules/articles';
import notice from './modules/notice';
import operation from './modules/operation';
import combination from './modules/combination';
import times from './modules/times';
import amount from './modules/amount';
import integral from "./modules/integral";
import commissioner from "./modules/commissioner";
import rewardcash from "./modules/rewardcash"
import rewardactive from './modules/rewardactive'
import commissioncode from './modules/commissioncode'
import site from './modules/site'
import scansite from './modules/scansite'
import infor from './modules/infor'
import province from "./modules/province";
import phone from "./modules/phone";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'base',
        component: base,
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/home')
            },
            //modules
            user,
            role,
            node,
            model,
            classes,
            road,
            terminal,
            others,
            simple,
            address,
            statistics,
            advert,
            goods,
            suning,
            advertcen,
            pool,
            lian,
            voucher,
            cardspool,
            express,
            blind,
            order,
            cardorder,
            coupon,
            draw,
            customer,
            finance,
            salescard,
            salescardlist,
            shopverify,
            commission,
            shopuser,
            articles,
            notice,
            operation,
            combination,
            times,
            amount,
            integral,
            commissioner,
            rewardcash,
            rewardactive,
            commissioncode,
            site,
            scansite,
            infor,
            province,
            phone,
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

export default {
    path: '/advertcen',
    name: 'advertcen',
    component: () => import(/* webpackChunkName: "advertcen" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "advertcen" */ '@/views/advertcen/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "advertcen" */ '@/views/advertcen/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "advertcen" */ '@/views/advertcen/modify')
        }
    ]
}

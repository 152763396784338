export default {
    path: '/combination',
    name: 'combination',
    component: () => import(/* webpackChunkName: "combination" */ '@/views/base'),
    children: [
        {
            name: 'combinationList',
            path: 'list',
            component: () => import(/* webpackChunkName: "combination" */ '@/views/combination/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "combination" */ '@/views/combination/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "combination" */ '@/views/combination/modify')
        },
        {
            path: 'look',
            component: () => import(/* webpackChunkName: "combination" */ '@/views/combination/look')
        },
    ]
}

export default {
    path: '/province',
    name: 'province',
    component: () => import(/* webpackChunkName: "articles" */ '@/views/base'),
    children: [
        {
            name: 'province',
            path: 'list',
            component: () => import(/* webpackChunkName: "articles" */ '@/views/province/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "articles" */ '@/views/province/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "articles" */ '@/views/province/modify')
        },
    ]
}

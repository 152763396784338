export default {
    path: '/site',
    name: 'site',
    component: () => import(/* webpackChunkName: "simple" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "simple" */ '@/views/site/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "simple" */ '@/views/site/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "simple" */ '@/views/site/modify')
        }
    ]
}
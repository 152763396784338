export default {
    path: '/simple',
    name: 'simple',
    component: () => import(/* webpackChunkName: "simple" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "simple" */ '@/views/simple/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "simple" */ '@/views/simple/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "simple" */ '@/views/simple/modify')
        }
    ]
}
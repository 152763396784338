export default {
    path: '/articles',
    name: 'articles',
    component: () => import(/* webpackChunkName: "articles" */ '@/views/base'),
    children: [
        {
            name: 'articlesList',
            path: 'list',
            component: () => import(/* webpackChunkName: "articles" */ '@/views/articles/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "articles" */ '@/views/articles/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "articles" */ '@/views/articles/modify')
        },
    ]
}

export default {
    data: [
        {
            name: '首页',
            index: '1',
            icon: 'el-icon-s-home',
            router: '/home',
            node: 'home',
        },
        {
            name: '系统管理',
            index: '2',
            icon: 'el-icon-s-tools',
            node: 'system_manage',
            subMenu: [
                {
                    name: '角色管理',
                    index: '2-1',
                    icon: 'el-icon-user-solid',
                    router: '/role/list',
                    node: 'role_manage',
                },
                {
                    name: '用户管理',
                    index: '2-2',
                    icon: 'el-icon-user',
                    router: '/user/list',
                    node: 'user_manage',
                },
                {
                    name: '节点管理',
                    index: '2-3',
                    icon: 'el-icon-price-tag',
                    router: '/node/list',
                    node: 'node_manage',
                },
                {
                    name: '模型管理',
                    index: '2-4',
                    icon: 'el-icon-film',
                    router: '/model/list',
                    node: 'model_manage',
                    subMenu: [
                        {
                            name: '规则管理',
                            index: '2-4-1',
                            icon: 'el-icon-user',
                            router: '/model/menage',
                            node: 'model_manage',
                        }
                    ]
                },
                {
                    name: '分类管理',
                    index: '2-5',
                    icon: 'el-icon-s-operation',
                    router: '/classes/list',
                    node: 'classes_manage',
                },
                {
                    name: '渠道管理',
                    index: '2-6',
                    icon: 'el-icon-guide',
                    router: '/road/list',
                    node: 'road_manage',
                },
                {
                    name: '终端管理',
                    index: '2-7',
                    icon: 'el-icon-cpu',
                    router: '/terminal/list',
                    node: 'terminal_manage',
                },
                {
                    name: '第三方管理',
                    index: '2-8',
                    icon: 'el-icon-s-custom',
                    router: '/others/list',
                    node: 'others_manage',
                },
                {
                    name: '快递管理',
                    index: '2-9',
                    icon: 'el-icon-s-promotion',
                    router: '/express/list',
                    node: 'express_manage',
                },
                {
                    name: '公告管理',
                    index: '2-10',
                    icon: 'el-icon-chat-line-round',
                    router: '/notice/list',
                    node: 'notice_manage',
                },
                {
                    name: '文章管理',
                    index: '2-11',
                    icon: 'el-icon-chat-line-round',
                    router: '/simple/list',
                    node: 'simple_manage',
                },
                {
                    name: '区域管理',
                    index: '2-12',
                    icon: 'el-icon-chat-line-round',
                    router: '/site/list',
                    node: 'site_manage',
                },

            ]
        },
        {
            name: '广告管理',
            index: '4',
            icon: 'el-icon-s-platform',
            node: 'advert_manage',
            subMenu: [
                {
                    name: '广告位管理',
                    index: '4-1',
                    icon: 'el-icon-monitor',
                    router: '/advert/list',
                    node: 'article_manage',
                },
                {
                    name: '广告内容管理',
                    index: '4-2',
                    icon: 'el-icon-reading',
                    router: '/advertcen/list',
                    node: 'adverten_manage',
                }
            ]
        },
        {
            name: '统计管理',
            index: '5',
            icon: 'el-icon-s-marketing',
            node: 'statistics_manage',
            subMenu: [
                {
                    name: '积分兑换券列表',
                    index: '5-1',
                    icon: 'el-icon-date',
                    router: '/statistics/score',
                    node: 'score_list_manage',
                },
                {
                    name: '站点核销信息列表',
                    index: '5-2',
                    icon: 'el-icon-tickets',
                    router: '/statistics/site',
                    node: 'site_list_manage',
                },
                {
                    name: '福彩分时统计列表',
                    index: '5-3',
                    icon: 'el-icon-tickets',
                    router: '/statistics/hours',
                    node: 'hours_list_manage',
                },
                {
                    name: '核销排行',
                    index: '5-4',
                    icon: 'el-icon-tickets',
                    router: '/statistics/salerank',
                    node: 'salerank_list_manage',
                },
                {
                    name: '站点数据统计',
                    index: '5-5',
                    icon: 'el-icon-tickets',
                    router: '/statistics/website',
                    node: 'website_list_manage',
                },
                {
                    name: '专管员数据统计',
                    index: '5-6',
                    icon: 'el-icon-tickets',
                    router: '/statistics/administrator',
                    node: 'administrator_list_manage',
                },
                {
                    name: '地市数据统计',
                    index: '5-7',
                    icon: 'el-icon-tickets',
                    router: '/statistics/city',
                    node: 'city_list_manage',
                },
                {
                    name: '用户个人邀请码统计',
                    index: '5-8',
                    icon: 'el-icon-tickets',
                    router: '/statistics/invite',
                    node: 'invite_list_manage',
                }
            ]
        },
        {
            name: '商品管理',
            index: '6',
            icon: 'el-icon-s-goods',
            node: 'goods_manage',
            subMenu: [
                {
                    name: '自营商品管理',
                    index: '6-1',
                    icon: 'el-icon-s-shop',
                    router: '/goods/list',
                    node: 'youjia_manage',
                    subMenu: [
                        {
                            name: '规则管理',
                            index: '6-1-1',
                            icon: 'el-icon-s-goods',
                            router: '/goods/specs',
                            node: 'youjia_manage',
                        }
                    ]
                },
                {
                    name: '苏宁商品管理',
                    index: '6-2',
                    icon: 'el-icon-sell',
                    router: '/suning/list',
                    node: 'suning_manage',
                },
                {
                    name: '商品池管理',
                    index: '6-3',
                    icon: 'el-icon-s-goods',
                    router: '/pool/list',
                    node: 'pool_manage',
                },
                {
                    name: '组合商品管理',
                    index: '6-4',
                    icon: 'el-icon-shopping-bag-1',
                    router: '/combination/list',
                    node: 'combination_manage',
                }
            ]
        },
        {
            name: '卡券管理',
            index: '7',
            icon: 'el-icon-s-finance',
            node: 'card_manage',
            subMenu: [
                {
                    name: '联联卡券管理',
                    index: '7-1',
                    icon: 'el-icon-money',
                    router: '/lian/list',
                    node: 'lian_manage',
                },
                {
                    name: '卡券池管理',
                    index: '7-2',
                    icon: 'el-icon-wallet',
                    router: '/cardspool/list',
                    node: 'cardspool_manage',
                }
            ]
        },
        {
            name: '活动管理',
            index: '8',
            icon: 'el-icon-s-flag',
            node: 'activity_manage',
            subMenu: [
                {
                    name: '优惠券管理',
                    index: '8-1',
                    icon: 'el-icon-collection',
                    router: '/voucher/list',
                    node: 'voucher_manage',
                },
                {
                    name: '优惠券日志',
                    index: '8-2',
                    icon: 'el-icon-notebook-1',
                    router: '/voucher/userList',
                    node: 'voucher_user_manage',
                },
                {
                    name: '奖品参数配置',
                    index: '8-3',
                    icon: 'el-icon-setting',
                    router: '/blind/list',
                    node: 'blind_boxes_manage',
                },
                {
                    name: '中奖记录',
                    index: '8-4',
                    icon: 'el-icon-date',
                    router: '/draw/list',
                    node: 'draw_manage',
                },
                {
                    name: '抽奖次数',
                    index: '8-5',
                    icon: 'el-icon-document',
                    router: '/times/list',
                    node: 'times_manage',
                },
            ]
        },
        {
            name: '订单管理',
            index: '9',
            icon: 'el-icon-s-order',
            node: 'order_manage',
            subMenu: [
                {
                    name: '商品订单管理',
                    index: '9-1',
                    icon: 'el-icon-s-order',
                    router: '/order/list',
                    node: 'orderlist_manage',
                    subMenu: [
                        {
                            name: '订单详情',
                            index: '9-1-1',
                            icon: 'el-icon-s-order',
                            router: '/order/modify',
                            node: 'orderlist_manage',
                        }
                    ]
                },
                {
                    name: '卡券订单管理',
                    index: '9-2',
                    icon: 'el-icon-s-order',
                    router: '/cardorder/list',
                    node: 'cardorder_manage',
                    subMenu: [
                        {
                            name: '订单详情',
                            index: '10-1-1',
                            icon: 'el-icon-s-order',
                            router: '/cardorder/modify',
                            node: 'cardorder_manage',
                        }
                    ]
                },
            ]
        },
        {
            name: '彩票管理',
            index: '10',
            icon: 'el-icon-s-ticket',
            node: 'coupon_manage',
            subMenu: [
                {
                    name: '福彩列表管理',
                    index: '10-1',
                    icon: 'el-icon-s-ticket',
                    router: '/coupon/list',
                    node: 'couponlist_manage',
                    subMenu: [
                        {
                            name: '查看彩票详情',
                            index: '10-1-1',
                            icon: 'el-icon-s-ticket',
                            router: '/coupon/modify',
                            node: 'couponlist_manage',
                        }
                    ]
                },
                {
                    name: '移动福彩日志管理',
                    index: '10-2',
                    icon: 'el-icon-document',
                    router: '/operation/list',
                    node: 'operation_manage',
                },
                {
                    name: '资金池明细管理',
                    index: '10-3',
                    icon: 'el-icon-document',
                    router: '/amount/list',
                    node: 'amount_manage',
                },
                {
                    name: '福彩专管员管理',
                    index: '10-4',
                    icon: 'el-icon-document',
                    router: '/commissioner/list',
                    node: 'commissioner_manage',
                },
                {
                    name: '福彩专管员活动记录',
                    index: '10-5',
                    icon: 'el-icon-document',
                    router: '/rewardactive/list',
                    node: 'rewardactive_manage',
                },
                {
                    name: '福彩店铺码管理',
                    index: '10-6',
                    icon: 'el-icon-document',
                    router: '/commissioncode/list',
                    node: 'commissioncode_manage',
                },
                {
                    name: '一店一码',
                    index: '10-7',
                    icon: 'el-icon-document',
                    router: '/scansite/list',
                    node: 'scansite_manage',
                }
            ]
        },
        {
            name: '客服管理',
            index: '11',
            icon: 'el-icon-s-comment',
            node: 'customer_manage',
            subMenu: [
                {
                    name: '工单管理',
                    index: '11-1',
                    icon: 'el-icon-document',
                    router: '/customer/list',
                    node: 'customer_manage',
                    subMenu: [
                        {
                            name: '开始操作',
                            index: '11-1-1',
                            router: '/customer/start',
                            node: 'customer_manage',
                        }
                    ]
                }
            ]
        },
        {
            name: '财务管理',
            index: '12',
            icon: 'el-icon-s-cooperation',
            node: 'finance_manage',
            subMenu: [
                {
                    name: '售卡佣金管理',
                    index: '12-1',
                    icon: 'el-icon-suitcase',
                    router: '/finance/list',
                    node: 'commission_manage',
                },
                {
                    name: '专管员提现管理',
                    index: '12-2',
                    icon: 'el-icon-suitcase',
                    router: '/rewardcash/list',
                    node: 'rewardcash_manage',
                }
            ]
        },
        {
            name: '售卡管理',
            index: '13',
            icon: 'el-icon-s-management',
            node: 'sale_card_manage',
            subMenu: [
                {
                    name: '套餐分类管理',
                    index: '13-1',
                    icon: 'el-icon-notebook-2',
                    router: '/salescard/list',
                    node: 'combo_category_manage',
                    subMenu: [
                        {
                            name: '套餐管理',
                            index: '13-1-1',
                            icon: 'el-icon-user',
                            router: '/salescard/combo',
                            node: 'combo_manage',
                        }
                    ]
                },
                {
                    name: '佣金配置',
                    index: '13-2',
                    icon: 'el-icon-edit-outline',
                    router: '/commission/list',
                    node: 'commission_list_manage',
                },
                {
                    name: '店主审核',
                    index: '13-3',
                    icon: 'el-icon-document-checked',
                    router: '/shopverify/list',
                    node: 'shop_verify_manage',
                },
                {
                    name: '店主管理',
                    index: '13-4',
                    icon: 'el-icon-document',
                    router: '/shopuser/list',
                    node: 'shop_owner_manage',
                },
                {
                    name: '售卡列表',
                    index: '13-5',
                    icon: 'el-icon-document-copy',
                    router: '/salescardlist/list',
                    node: 'sale_card_list_manage',
                },
                {
                    name: '售卡宣传',
                    index: '13-6',
                    icon: 'el-icon-data-analysis',
                    router: '/articles/list',
                    node: 'articles_manage',
                },
                {
                    name: '店主群发消息管理',
                    index: '13-7',
                    icon: 'el-icon-data-analysis',
                    router: '/infor/list',
                    node: 'infor_manage',
                },
                {
                    name: '号码池省份管理',
                    index: '13-8',
                    icon: 'el-icon-data-analysis',
                    router: '/province/list',
                    node: 'province_manage',
                },
                {
                    name: '电话号码池管理',
                    index: '13-9',
                    icon: 'el-icon-data-analysis',
                    router: '/phone/list',
                    node: 'phone_manage',
                }
            ]
        },
        {
            name: '积分管理',
            index: '14',
            icon: 'el-icon-s-claim',
            node: 'integral_manage',
            subMenu: [
                {
                    name: '积分兑换总表',
                    index: '14-1',
                    icon: 'el-icon-document',
                    router: '/integral/list',
                    node: 'integral_summary_manage',
                },
                {
                    name: '核销数据统计',
                    index: '14-2',
                    icon: 'el-icon-document',
                    router: '/integral/verification',
                    node: 'integral_report_manage',
                },
                {
                    name: '兑换券订单详情信息统计',
                    index: '14-3',
                    icon: 'el-icon-document',
                    router: '/integral/order',
                    node: 'integral_order_manage',
                },
                {
                    name: '额外中奖机会抽取统计',
                    index: '14-4',
                    icon: 'el-icon-document',
                    router: '/integral/extra',
                    node: 'integral_extra_manage',
                },
                {
                    name: '积分资金池资金统计',
                    index: '14-5',
                    icon: 'el-icon-document',
                    router: '/integral/pools',
                    node: 'integral_pools_manage',
                },
                {
                    name: '商城订单统计',
                    index: '14-6',
                    icon: 'el-icon-document',
                    router: '/integral/shoporder',
                    node: 'integral_order_statistics_manage',
                },
                {
                    name: '商城产品销售统计',
                    index: '14-7',
                    icon: 'el-icon-document',
                    router: '/integral/sale',
                    node: 'integral_product_sales_manage',
                },
                {
                    name: '用户画像',
                    index: '14-8',
                    icon: 'el-icon-document',
                    router: '/integral/portrait',
                    node: 'integral_user_portrait_manage',
                },
                {
                    name: '流量转化统计',
                    index: '14-9',
                    icon: 'el-icon-document',
                    router: '/integral/flow',
                    node: 'integral_flow_manage',
                }
            ]
        }
    ]
}

export default {
    path: '/shopuser',
    name: 'shopuser',
    component: () => import(/* webpackChunkName: "shopuser" */ '@/views/base'),
    children: [
        {
            name:'shopUserView',
            path: 'list',
            component: () => import(/* webpackChunkName: "shopuser" */ '@/views/shopuser/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "shopuser" */ '@/views/shopuser/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "shopuser" */ '@/views/shopuser/modify')
        },
        {
            name:'memberListView',
            path: 'memberList',
            component: () => import(/* webpackChunkName: "shopuser" */ '@/views/shopuser/memberList')
        },
        {
            path: 'orderList',
            component: () => import(/* webpackChunkName: "shopuser" */ '@/views/shopuser/orderList')
        },
        {
            path: 'memberOrderList',
            component: () => import(/* webpackChunkName: "shopuser" */ '@/views/shopuser/memberOrderList')
        },
    ]
}

export default {
    namespaced: true,
    state: {
        access: sessionStorage.getItem('access')
    },
    mutations: {
        setAccess(state, val) {
            state.access = val
            sessionStorage.setItem('access', val)
        },
        clearAccess(state) {
            state.access = ''
            sessionStorage.removeItem('access')
        },
    },
    actions: {},
    getters: {
        check: (state) => (name) => {
            if (state.access && state.access.indexOf(name) === -1) {
                return false
            } else {
                return true
            }
        }
    }
}
export default {
    path: '/road',
    name: 'road',
    component: () => import(/* webpackChunkName: "road" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "road" */ '@/views/road/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "road" */ '@/views/road/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "road" */ '@/views/road/modify')
        }
    ]
}
export default {
    path: '/scansite',
    name: 'scansite',
    component: () => import(/* webpackChunkName: "operation" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "operation" */ '@/views/scansite/list')
        }
    ]
}

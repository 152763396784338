export default {
    path: '/cardspool',
    name: 'cardspool',
    component: () => import(/* webpackChunkName: "cardspool" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "cardspool" */ '@/views/cardspool/list')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "cardspool" */ '@/views/cardspool/modify')
        },
        {
            path: 'price',
            component: () => import(/* webpackChunkName: "cardspool" */ '@/views/cardspool/price')
        },
        {
            path: 'shelf',
            component: () => import(/* webpackChunkName: "cardspool" */ '@/views/cardspool/shelf')
        },
    ]
}

<template>
  <span>
    <el-link type="primary" @click="passwordDialogVisible = true">修改密码</el-link>
    <el-dialog
        title="修改密码"
        :visible.sync="passwordDialogVisible"
        width="30%"
        :before-close="closePasswordDialogHandler"
        :append-to-body="true">
      <el-form ref="passwordForm" :model="passwordForm" :rules="rules" label-width="80px">
        <el-form-item label="原密码" prop="oldUserPassword">
          <el-input v-model="passwordForm.oldUserPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="userPassword">
          <el-input v-model="passwordForm.userPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="userPasswordCheck">
          <el-input v-model="passwordForm.userPasswordCheck" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </span>
</template>

<script>
import api from "@/api/admin";

export default {
  name: "ModifyPassword",
  data() {
    const validatePass = (rule, value, callback) => {
      if (this.passwordForm.userPasswordCheck !== '') {
        this.$refs.passwordForm.validateField('userPasswordCheck');
      }
      callback();
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value !== this.passwordForm.userPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      passwordDialogVisible: false,
      passwordForm: {
        oldUserPassword: '',
        userPassword: '',
        userPasswordCheck: '',
      },
      rules: {
        oldUserPassword: [
          {required: true, message: '请输入原密码', trigger: 'blur'}
        ],
        userPassword: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ],
        userPasswordCheck: [
          {required: true, message: '请再次输入密码', trigger: 'blur'},
          {validator: validatePassCheck, trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          api.modifyPassword(this, this.passwordForm.oldUserPassword, this.passwordForm.userPassword)
              .then(res => {
                if (res.data.result === 0) {
                  this.$message({
                    message: '修改成功',
                    type: 'success'
                  })
                  this.$store.commit('user/clearToken')
                  this.$router.push('/login')
                } else {
                  this.$message.error(res.data.message)
                }
              })
        } else {
          return false;
        }
      });
    },
    closePasswordDialogHandler(done) {
      done()
    }
  }
}
</script>

<style scoped>

</style>
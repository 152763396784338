export default {
    path: '/draw',
    name: 'draw',
    component: () => import(/* webpackChunkName: "draw" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "draw" */ '@/views/draw/list')
        },
    ]
}

export default {
    path: '/suning',
    name: 'suning',
    component: () => import(/* webpackChunkName: "suning" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "suning" */ '@/views/suning/list')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "suning" */ '@/views/suning/modify')
        },
        {
            path: 'price',
            component: () => import(/* webpackChunkName: "suning" */ '@/views/suning/price')
        },
        {
            path: 'shelf',
            component: () => import(/* webpackChunkName: "suning" */ '@/views/suning/shelf')
        },
    ]
}

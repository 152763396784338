export default {
    path: '/others',
    name: 'others',
    component: () => import(/* webpackChunkName: "others" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "others" */ '@/views/others/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "others" */ '@/views/others/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "others" */ '@/views/others/modify')
        }
    ]
}
export default {
    path: '/integral',
    name: 'integral',
    component: () => import(/* webpackChunkName: "statistics" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/integral/list')
        },
        {
            path: 'verification',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/integral/verification')
        },
        {
            path: 'order',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/integral/order')
        },
        {
            path: 'extra',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/integral/extra')
        },
        {
            path: 'pools',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/integral/pools')
        },
        {
            path: 'shoporder',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/integral/shoporder.vue')
        },
        {
            path: 'sale',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/integral/sale.vue')
        },
        {
            path: 'portrait',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/integral/portrait.vue')
        },
        {
            path: 'flow',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/integral/flow.vue')
        },
    ]
}
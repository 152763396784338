export default {
    path: '/statistics',
    name: 'statistics',
    component: () => import(/* webpackChunkName: "statistics" */ '@/views/base'),
    children: [
        {
            path: 'score',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/statistics/score')
        },
        {
            path: 'site',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/statistics/site')
        },
        {
            path: 'hours',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/statistics/hours')
        },
        {
            path: 'salerank',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/statistics/salerank')
        },
        {
            path: 'website',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/statistics/website')
        },
        {
            path: 'administrator',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/statistics/administrator')
        },
        {
            path: 'city',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/statistics/city')
        },
        {
            path: 'invite',
            component: () => import(/* webpackChunkName: "statistics" */ '@/views/statistics/invite')
        }
    ]
}
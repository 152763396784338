export default {
    path: '/address',
    name: 'address',
    component: () => import(/* webpackChunkName: "address" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "address" */ '@/views/address/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "address" */ '@/views/address/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "address" */ '@/views/address/modify')
        }
    ]
}
export default {
    path: '/pool',
    name: 'pool',
    component: () => import(/* webpackChunkName: "pool" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "pool" */ '@/views/pool/list')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "pool" */ '@/views/pool/modify')
        },
        {
            path: 'price',
            component: () => import(/* webpackChunkName: "pool" */ '@/views/pool/price')
        },
        {
            path: 'shelf',
            component: () => import(/* webpackChunkName: "pool" */ '@/views/pool/shelf')
        },
    ]
}

export default {
    path: '/times',
    name: 'times',
    component: () => import(/* webpackChunkName: "draw" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "draw" */ '@/views/times/list')
        },
    ]
}

export default {
    namespaced: true,
    state: {
        token: sessionStorage.getItem('token')
    },
    mutations: {
        setToken(state, val) {
            state.token = val
            sessionStorage.setItem('token', val)
        },
        clearToken(state) {
            state.token = ''
            sessionStorage.removeItem('token')
        }
    },
    actions: {},
    getters: {}
}
export default {
    path: '/finance',
    name: 'finance',
    component: () => import(/* webpackChunkName: "finance" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/list')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "finance" */ '@/views/finance/modify')
        },
    ]
}

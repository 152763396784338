export default {
    path: '/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "customer" */ '@/views/base'),
    children: [
        {
            name: 'customerList',
            path: 'list',
            component: () => import(/* webpackChunkName: "customer" */ '@/views/customer/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "customer" */ '@/views/customer/add')
        },
        {
            path: 'start',
            component: () => import(/* webpackChunkName: "customer" */ '@/views/customer/start')
        },
        {
            path: 'over',
            component: () => import(/* webpackChunkName: "customer" */ '@/views/customer/over')
        }
    ]
}

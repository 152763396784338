export default {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '@/views/base'),
    children: [
        {
            name: 'userList',
            path: 'list',
            component: () => import(/* webpackChunkName: "user" */ '@/views/user/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "user" */ '@/views/user/add')
        },
        {
            path: 'start',
            component: () => import(/* webpackChunkName: "customer" */ '@/views/user/start')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "user" */ '@/views/user/modify')
        }
    ]
}
<template>
  <div>
    <slot v-if="isShow"></slot>
  </div>
</template>

<script>
export default {
  name: "CheckAccess",
  props: [
    'name'
  ],
  computed: {
    isShow() {
      return this.$store.getters["auth/check"](this.name)
    }
  }
}
</script>

<style scoped>

</style>
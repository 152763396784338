export default {
    path: '/rewardcash',
    name: 'rewardcash',
    component: () => import(/* webpackChunkName: "finance" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "finance" */ '@/views/rewardcash/list')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "finance" */ '@/views/rewardcash/modify')
        },
    ]
}

export default {
    path: '/terminal',
    name: 'terminal',
    component: () => import(/* webpackChunkName: "terminal" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "terminal" */ '@/views/terminal/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "terminal" */ '@/views/terminal/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "terminal" */ '@/views/terminal/modify')
        }
    ]
}
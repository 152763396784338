export default {
    path: '/classes',
    name: 'classes',
    component: () => import(/* webpackChunkName: "classes" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "classes" */ '@/views/classes/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "classes" */ '@/views/classes/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "classes" */ '@/views/classes/modify')
        }
    ]
}
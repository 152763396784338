export default {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '@/views/base'),
    children: [
        {
            name:'orderList',
            path: 'list',
            component: () => import(/* webpackChunkName: "order" */ '@/views/order/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "order" */ '@/views/order/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "order" */ '@/views/order/modify')
        },
    ]
}

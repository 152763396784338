import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/css/main.less'
import axios from 'axios'
import VueAxios from 'vue-axios'
import AmapVue from '@amap/amap-vue';

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(VueAxios, axios)
Vue.use(AmapVue);

AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = 'af4d5599ca505d88d81e8e433ec62063';
AmapVue.config.plugins = [
    'AMap.ToolBar',
    'AMap.MoveAnimation',
    // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];

axios.interceptors.request.use(function (config) {
    config.headers['Token'] = window.sessionStorage.getItem('token')
    return config
});



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

export default {
    path: '/blind',
    name: 'blind',
    component: () => import(/* webpackChunkName: "blind" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "blind" */ '@/views/blind/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "blind" */ '@/views/blind/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "blind" */ '@/views/blind/modify')
        },
    ]
}

export default {
    path: '/node',
    name: 'node',
    component: () => import(/* webpackChunkName: "node" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "node" */ '@/views/node/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "node" */ '@/views/node/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "node" */ '@/views/node/modify')
        }
    ]
}
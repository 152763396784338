<template>
    <div v-if="confirmToken">
        <el-container>
            <el-header>
                <div id="header-left">
                    <img id="logo" alt="万源互通后台管理系统" src="@/assets/images/logo.jpg"/>
                    <h1>万源互通后台管理系统</h1>
                    <div class="clear"></div>
                </div>
                <div id="header-button">
                    <modify-password></modify-password>
                    <el-link type="primary" @click="logoutHandler">注销</el-link>
                </div>
            </el-header>
            <el-container>
                <el-aside>
                    <Menu/>
                </el-aside>
                <el-main>
                    <el-breadcrumb>
                        <template v-for="(item, key) in breadCrumb">
                            <el-breadcrumb-item :to="item.router" :key="key">{{ item.name }}</el-breadcrumb-item>
                        </template>
                    </el-breadcrumb>
                    <div id="content">
                        <transition name="component-fade" mode="out-in">
                            <router-view></router-view>
                        </transition>
                    </div>
                </el-main>
            </el-container>
            <el-footer>
                <div>©2022-{{ new Date().getFullYear() }} 北京万源互通物联技术有限公司</div>
                <div>
                    <a href="https://beian.miit.gov.cn" target="_blank">京ICP备2023012481号</a>
                </div>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
import api from '@/api/admin'
import Menu from './components/Menu'
import ModifyPassword from "@/layout/components/ModifyPassword";

export default {
    name: "BaseLayout",
    components: {
        Menu,
        ModifyPassword,
    },
    data() {
        return {
            confirmToken: false
        }
    },
    mounted() {
        api.checkToken(this)
            .then(res => {
                if (res.data.result !== 0) {
                    this.$store.commit('nav/clearBreadCrumb')
                    this.$store.commit('user/clearToken')
                    this.$router.push('/login')
                } else {
                    api.newToken(this).then(res=>{
                      this.$store.commit('auth/setAccess', res.data.access)
                      this.confirmToken = true
                    })

                }
            })
    },
    computed: {
        breadCrumb() {
            return this.$store.state.nav.breadCrumb
        }
    },
    methods: {
        logoutHandler() {
            this.$confirm('是否确认注销登录？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'info'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '注销成功'
                });
                this.$store.commit('user/clearToken')
                this.$store.commit('auth/clearAccess')
                this.$router.push('/login')
            }).catch(() => {

            });

        }
    }
}
</script>

<style lang="less" scoped>
.clear {
  clear: both;
}

.el-container {
  min-height: 100vh;

  .el-header {
    color: white;
    line-height: 60px;
    background-color: dodgerblue;
    padding: 0;
    z-index: 2;

    #header-left {
      width: 300px;
      height: 60px;
      float: left;
    }

    #logo {
      height: 60px;
      display: block;
      float: left;
      margin-left: 20px;
    }

    h1 {
      display: block;
      float: left;
      margin-left: 20px;
      font-size: 18px;
    }

    #header-button {
      text-align: right;
      background-color: white;
      float: left;
      width: calc(100% - 300px - 20px);
      padding-right: 20px;

      .el-link {
        margin-left: 20px;
      }
    }
  }

  .el-container {
    min-height: calc(100vh - 120px);

    .el-aside {
      height: 100vh;
      overflow-x: hidden;
      overflow-y: scroll;
      background-color: white;
      box-shadow: 0 0 10px #999;
      z-index: 1;

      .el-menu {
        border-right: none;
      }
    }

    .el-main {
      background-color: #f0f2f5;

      #content {
        background-color: white;
        margin-top: 18px;
        padding: 20px;
      }
    }
  }
}

.el-footer {
  color: white;
  line-height: 30px;
  text-align: center;
  background-color: dodgerblue;

  a {
    text-decoration: none;
    color: white;
  }
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .5s ease;
}

.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
</style>

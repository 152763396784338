export default {
    path: '/shopverify',
    name: 'shopverify',
    component: () => import(/* webpackChunkName: "shopverify" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "shopverify" */ '@/views/shopverify/list')
        },
    ]
}
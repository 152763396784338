export default {
    namespaced: true,
    state: {
        breadCrumb: [{
            'name': '首页',
            'router': '/home',
        }],
    },
    mutations: {
        addBreadCrumb(state, val) {
            state.breadCrumb.push({
                name: val.name,
                router: val.router,
            })
        },
        clearBreadCrumb(state) {
            state.breadCrumb = []
        },
    },
    actions: {},
    getters: {}
}
export default {
    path: '/goods',
    name: 'goods',
    component: () => import(/* webpackChunkName: "goods" */ '@/views/base'),
    children: [
        {
            name: 'goodsList',
            path: 'list',
            component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/modify')
        },
        {
            path: 'specs',
            component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/specs')
        },
        {
            path: 'specsModify',
            component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/specsModify')
        },
        {
            path: 'addSpecs',
            component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/addSpecs')
        },
        {
            path: 'price',
            component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/price')
        },
        {
            path: 'shelf',
            component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/shelf')
        }
    ]
}

export default {
    path: '/commissioner',
    name: 'commissioner',
    component: () => import(/* webpackChunkName: "classes" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "classes" */ '@/views/commissioner/list')
        },
        {
            path: 'active',
            component: () => import(/* webpackChunkName: "classes" */ '@/views/commissioner/active')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "classes" */ '@/views/commissioner/add')
        },
        {
            path: 'infor',
            component: () => import(/* webpackChunkName: "classes" */ '@/views/commissioner/infor')
        },
        {
            path: 'look',
            component: () => import(/* webpackChunkName: "classes" */ '@/views/commissioner/look')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "classes" */ '@/views/commissioner/modify')
        },
    ]
}
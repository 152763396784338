export default {
    path: '/amount',
    name: 'amount',
    component: () => import(/* webpackChunkName: "classes" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "classes" */ '@/views/amount/list')
        },
    ]
}
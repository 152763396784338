export default {
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "notice" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "notice" */ '@/views/notice/list')
        },
        {
            path: 'add',
            component: () => import(/* webpackChunkName: "notice" */ '@/views/notice/add')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "notice" */ '@/views/notice/modify')
        }
    ]
}

export default {
    path: '/lian',
    name: 'lian',
    component: () => import(/* webpackChunkName: "lian" */ '@/views/base'),
    children: [
        {
            path: 'list',
            component: () => import(/* webpackChunkName: "lian" */ '@/views/lian/list')
        },
        {
            path: 'modify',
            component: () => import(/* webpackChunkName: "lian" */ '@/views/lian/modify')
        },
        {
            path: 'price',
            component: () => import(/* webpackChunkName: "lian" */ '@/views/lian/price')
        },
        {
            path: 'shelf',
            component: () => import(/* webpackChunkName: "lian" */ '@/views/lian/shelf')
        }
    ]
}
